<script>
export default {
  name: "ArticleHeader",
  props: {
    title: String,
    subtitle: String,
  },
}
</script>

<template>
  <header class="page-header">
    <h1 class="page-title">{{ title }}.</h1>
    <div role="doc-subtitle">{{ subtitle }}.</div>
  </header>
</template>

<style lang="scss">
.page-header {
  margin-top: 10vmin;
  padding: 10vh map-get(map-get($breakpoints, "mobile"), "lateral-margin");
  @include prevent-code-inflating;

  @include larger-than-mobile {
    margin-top: 20vmin;
    padding: 10vh map-get(map-get($breakpoints, "desktop"), "lateral-margin");
  }
}
</style>

