<template>
    <article>
        <ArticleHeader :title="title" :subtitle="subtitle"></ArticleHeader>
        <section class="intro services">
            <p>
                Je conçois et développe votre site web aligné avec vos valeurs. Je vous accompage tout au long du
                processus
                de création en fonction de vos besoins.
            </p>
        </section>
        <SectionTemplate v-bind="features" class="features">
            <HexagonItem v-for="feature in features.details" :key="feature" v-bind="feature"></HexagonItem>
        </SectionTemplate>
        <ButtonTemplate v-if="isLanding()" text="En savoir plus" icon="/assets/icons/magnifying-glass.svg"
            classes="see-more button--border full-width-on-mobile rounded-corners" @click="goToRoute('services')">
        </ButtonTemplate>
        <slot name="fullPage"></slot>
    </article>
</template>

<script>
import SectionTemplate from '@/components/SectionTemplate.vue';
import HexagonItem from '@/components/HexagonItem.vue';
import ArticleHeader from '@/components/ArticleHeader.vue';
import ButtonTemplate from './ButtonTemplate.vue';

export default {
    name: "servicesPreview",
    data() {
        return {
            title: "Prestations",
            subtitle: "Ce que je peux faire pour vous",
            features: {
                title: "Votre site engagé",
                subtitle: "Je crée des sites qui ont du sens",
                details: {
                    humanCentered: {
                        imageFilename: "person-in-hands.svg",
                        
                        title: "Centré sur l'humain",
                        description: "Un site réellement utile et facile d’utilisation, reposant sur une compréhension experte des comportements humains et des besoins des utilisateurs.",
                    },
                    inclusive: {
                        imageFilename: "heart-in-hands.svg",
                        
                        title: "Inclusif",
                        description: "Un site pensé pour de vrais utilisateurs, responsive et accessible, pour une utilisation équitable et universelle indépendante des contraintes, handicaps ou limitations.",
                    },
                    sustainable: {
                        imageFilename: "leaf-on-hand.svg",
                        
                        title: "Eco-responsable",
                        description: "Un site éco-conçu dans une démarche de sobriété numérique. L’éco-conception réduit notre impact environnemental tout en répondant efficacement aux besoins essentiels des utilisateurs.",
                    },
                },
            },
        };
    },
    methods: {
        goToRoute(routeName) {
            let routeData = this.$router.resolve({ name: routeName });
            return window.open(routeData.href, '_self');
        },
        isLanding() {
            return this.$route.name === "landing"
        },
    },
    components: { ArticleHeader, HexagonItem, SectionTemplate, ButtonTemplate }

}
</script>

<style lang="scss">
.intro.services {
    padding-top: 0;
    padding-bottom: 0;
}

.see-more {
    display: block;
    margin: map-get($map: $spacing, $key: "lg") auto map-get($map: $spacing, $key: "md") auto;
    width: 80%;

    @include larger-than-mobile {
        margin-bottom: map-get($map: $spacing, $key: "xxl");
    }

}
</style>