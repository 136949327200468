<template>
  <div class="hexagon-card" :class="imageSide">
    <NwImg class="hexagon" :src="'/assets/images/' + imageFilename" :alt="title" />
    <h3 class="card-title title">{{ title }} <span class="subtitle">{{ subtitle }}</span></h3>
    <p class="description">{{ description }}</p>
  </div>
</template>
<script>
import NwImg from '@/components/NwImg.vue';
export default {
  name: "HexagonItem",
  props: {
    imageFilename: String,
    backgroundColor: String,
    title: String,
    subtitle: String,
    description: String,
    imageSide: { type: String, default: "left" },
  },
  components: { NwImg }
}
</script>

<style lang="scss" >
.hexagon-card {
  display: grid;
  grid-template-areas: "hexagon title"
    "description description";
  @include prevent-code-inflating;

  @include larger-than-mobile {
    grid-template-areas: "hexagon title"
      "hexagon description";
  }

  grid-template-columns: min-content 1fr;
  align-items: center;
  @include hexagon-sizing(max(10vh, 6.25rem));

  .hexagon {
    margin-top: 0;
    grid-area: hexagon;
  }

  .title {
    grid-area: title;
    margin-left: map-get($map: $spacing, $key: "base");
  }

  .subtitle {
    display: block;
  }

  .description {
    grid-area: description;

    @include larger-than-mobile {
      margin-left: map-get($map: $spacing, $key: "base");
    }
  }

  .hexagon__picture {
    float: inline-start;
    margin: 0.75rem 0.75rem auto 0;
  }

  &:not(.hexagon-card:first-of-type) {
    margin-top: map-get($map: $spacing, $key: "xl")
  }

  @include larger-than-mobile {
    @include hexagon-sizing($hexagon-size);
    // align-items: center;
    margin-top: -2vw;

    &.left .hexagon {
      margin-right: 18px;

      .hexagon__background {
        transform: rotate(180deg);
      }
    }

    &.right {
      flex-direction: row-reverse;

      .hexagon {
        margin-left: 18px;
      }

      .hexagon__legend {
        text-align: right;
      }
    }
  }
}


[class^="hexagon dark"] {
  .hexagon__img {
    filter: $svg-filter--neutral-light;
  }
}

[class^="hexagon light"] {
  .hexagon__img {
    filter: $svg-filter--main-color;
  }
}

[class^="hexagon green"] {
  .hexagon__img {
    filter: $svg-filter--dark-green;
  }
}
</style>

