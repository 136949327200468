<template>
    <article>
        <ArticleHeader title="A propos" subtitle="Qui je suis"></ArticleHeader>
        <section class="bio about">
            <picture class="img-container">
                <source srcset="@/assets/images/picture-bio.webp" type="image/webp">
                <source srcset="@/assets/images/picture-bio.png" type="image/png">
                <NwImg class="profile-picture" src="./assets/images/picture-bio.png" alt="Photo de Lison Fanuel" />
            </picture>
            <div class="presentation">
                <h2 class="card-title">Je suis Lison, développeuse web et docteure en psychologie cognitive.</h2>
                <p>Je crée des sites web simples et efficaces en alliant mes connaissances sur le cerveau et mon talent
                    pour la résolution de problèmes. Grâce à mon expertise du fonctionnement humain, j’apporte un regard
                    unique sur l’inclusivité et l’accessibilité numérique.</p>
                <p>Ma conscience écologique forte laisse son empreinte dans mes choix professionnels. Je suis attachée à
                    travailler avec des entreprises à forte valeur sociale et environnementale et collaborer avec des
                    personnes engagées, qui portent des projets qui ont du sens.</p>
                <p>J’aime aussi découvrir, explorer, apprendre, relever de nouveaux défis et trouver la combinaison
                    unique qui
                    répondra
                    à chaque situation. Contactez-moi pour discuter de vos projets !</p>
                <ButtonTemplate v-bind="contactButton"></ButtonTemplate>
            </div>
        </section>
        <ButtonTemplate v-if="isLanding()" text="Mieux me connaitre" icon="/assets/icons/nut-cracker.svg"
            classes="more-about-me see-more button--border rounded-corners" @click="goToRoute('about')">
        </ButtonTemplate>
        <slot name="fullPage"></slot>
    </article>
</template>

<script>
import ArticleHeader from './ArticleHeader.vue';
import ButtonTemplate from './ButtonTemplate.vue'
import NwImg from '@/components/NwImg.vue'

export default {
    data() {
        return {
            contactButton: {
                text: "Me contacter",
                icon: "../assets/icons/paper-plane.svg",
                classes: "button--text no-margin",
                action: () => {
                    this.$router.push('/contact')
                },
            },
        }
    },
    methods: {
        goToRoute(routeName) {
            let routeData = this.$router.resolve({ name: routeName });
            return window.open(routeData.href, '_self');
        },
        isLanding() {
            return this.$route.name === "landing"
        },
    },
    components: { ArticleHeader, ButtonTemplate, NwImg }
}


</script>

<style lang="scss">
.bio {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-top: 0;
    padding-bottom: 0;

    padding-bottom: map-get($map: $spacing, $key: "xl");

    @include desktop-only {
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .img-container {
        width: 300px;
        height: 300px;
        border-radius: 50%;

        @include larger-than-mobile {
            margin-right: map-get($map: $spacing, $key: "xl");
        }
    }

    .profile-picture {
        object-fit: fill;
        border-radius: 50%;
        height: calc(300px - 2 * map-get($map: $spacing, $key: "sm"));
        width: calc(300px - 2 * map-get($map: $spacing, $key: "sm"));
        margin: map-get($map: $spacing, $key: "sm");
    }

    .presentation .button {
        margin-left: 0;
        margin-right: 0;

    }
}

button.more-about-me {
    display: flex;
    justify-content: center;

    &>* {
        margin: auto 0;
    }
}
</style>