<template>
  <button class="button" :class="classes" @click="action">
    <NwImg :src="icon" class="button__icon" alt="" />
    <span>{{ text }}</span>
  </button>
</template>

<script>
import NwImg from '@/components/NwImg.vue';

export default {
  name: "ButtonTemplate",
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: String,
    classes: String,
    action: Function,
  },
  components: { NwImg }
}
</script>

<style lang="scss">
@mixin button($color,
  $bg-color,
  $hover-color,
  $hover-bg-color) {
  margin: map-get($map: $spacing, $key: "xs") map-get($map: $spacing, $key: "base");
  padding: map-get($map: $spacing, $key: "xs") map-get($map: $spacing, $key: "lg");
  width: fit-content;
  border-radius: 8px;
  font-size: 1rem;
  color: $color;
  background-color: $bg-color;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1;
  min-height: calc(map-get($map: $spacing, $key: "md") + 4 * map-get($map: $spacing, $key: "xs"));
  cursor: pointer;

  .button__icon {
    display: inline-block;
    margin: auto 0;
    width: 0;
    opacity: 0;
    vertical-align: middle;

    line-height: 1;
    text-align: center;
    font-weight: bold;
  }

  &:hover,
  &:active,
  &:focus,
  &:focus-within,
  &:focus-visible,
  &:target {
    outline: none;
    background-color: $hover-bg-color;
    color: $hover-color;
    box-shadow: $spacing-unit $spacing-unit calc($spacing-unit / 2) scale-color($color: $neutral-dark, $alpha: -50%);
    transition: all 0.25s ease-in-out;

    .button__icon {
      width: map-get($map: $spacing, $key: "md");
      margin-right: map-get($map: $spacing, $key: "xs");
      opacity: 1;
    }
  }
}

.button--fill {
  @include button($color: $white,
    $bg-color: $contrasting-color,
    $hover-color: $white,
    $hover-bg-color: $main-color--light );
  position: relative;

  .button__icon {
    filter: invert(1);
  }
}

.button--border {
  @include button($color: $contrasting-color,
    $bg-color: lighten($neutral-light, 10%),
    $hover-color: $main-color--light,
    $hover-bg-color: lighten($neutral-light, 10%));
  position: relative;
  outline: solid 2px $contrasting-color;

  .button__icon {
    filter: $svg-filter--contrasting-color
  }

  &:hover,
  &:focus,
  &:active {
    // box-shadow: none;
    outline: solid 2px $main-color--light;

    .button__icon {
      filter: $svg-filter--main-color-light
    }
  }



}

.button--text {
  @include button($color: $contrasting-color,
    $bg-color: none,
    $hover-color: $main-color--light,
    $hover-bg-color: lighten($neutral-light, 10%));

  &.no-margin {
    margin: 0;
    padding-left: 0;
    padding-right: 0;

    &:hover,
    &:focus,
    &:active,
    &:focus-visible,
    &:focus-within {
      padding: map-get($map: $spacing, $key: "xs") map-get($map: $spacing, $key: "lg");
    }
  }

  &:hover,
  &:focus,
  &:active {
    // box-shadow: none;
    outline: solid 2px $main-color--light
  }

  .button__icon {
    filter: $svg-filter--main-color-light;
  }
}

.full-width-on-mobile {
  @include mobile-only {
    margin: map-get($map: $spacing, $key: "xs") 0;
    width: 100%;
    text-align: center;
  }
}

.rounded-corners {
  border-radius: calc(map-get($map: $spacing, $key: "md") + 4 * map-get($map: $spacing, $key: "xs"));
}
</style>