<script>
import TheHeader from "./components/TheHeader.vue";
import TheFooter from "./components/TheFooter.vue";


export default {
	name: "App",
	components: {
		TheHeader,
		TheFooter,
	},
	data() {
		return {
		}
	},
	computed: {
		isLanding() {
			return this.$route.name === "landing"
		},
		isMobile() {
			return (window.innerWidth <= 480)
		}
	},
};
</script>

<template>
	<TheHeader />
	<router-view></router-view>
	<TheFooter :class="{ 'section--colored-bg': isLanding }" />
</template>

