<template>
    <article>
        <header class="page-header">
            <h1 class="page-title">Contact.</h1>
            <div role="doc-subtitle">Laissez-moi un message !</div>
        </header>
        <section class="contact-page">
            <ContactForm />
            <aside>
                <ButtonTemplate v-bind="downloadCVButton"></ButtonTemplate>
                <p>Retrouvez-moi ici:</p>

                <div class="social-media-container">
                    <a :href="socialMedia.link" v-for="socialMedia in socialMedias" :key="socialMedia"
                        class="social-media" target="_blank" :aria-label=socialMedia.label>
                        <LabelledLogo :icon-path="'assets/icons/' + socialMedia.icon + '.svg'" size="small-size"
                            color="contrasting">
                        </LabelledLogo>
                    </a>
                </div>
            </aside>
        </section>
    </article>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import ButtonTemplate from '@/components/ButtonTemplate.vue';
import LabelledLogo from '@/components/LabelledLogo.vue';
import ContactForm from '@/components/ContactForm.vue';

export default {
    name: "ContactPage",
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            name: "",
            email: "",
            phone: "",
            message: "",
            sendMessage: {
                text: "Envoyer",
                icon: "../assets/icons/paper-plane.svg",
                classes: "button--fill full-width-on-mobile",
            },
            isSent: false,
            isSendingError: false,
            errorRequiredErrorMessage: "Merci de remplir ce champ.",
            emailRequiredErrorMessage: "Merci d'indiquer une adresse email valide.",
            downloadCVButton: {
                text: "Télécharger mon CV",
                icon: "../assets/icons/download.svg",
                classes: "button--text no-margin",
                action: function () {
                    const filePath = `../assets/CV_Lison Fanuel - web dev.pdf`;
                    window.open(filePath, "_blank");
                },
            },
            socialMedias: [
                { icon: "linkedin", link: "https://www.linkedin.com/in/lison-fanuel-phd-662585bb/", label: "LinkedIn" },
                { icon: "github", link: "https://github.com/LisonF", label: "GitHub" },
                { icon: "researchgate", link: "https://www.researchgate.net/profile/Lison-Fanuel", label: "ResearchGate" }],


        }
    },

    components: { LabelledLogo, ContactForm, ButtonTemplate }
}
</script>

<style lang="scss">
.contact-page {
    padding-top: 0;
    display: grid;
    grid-template-areas: "intro"
        "form"
        "aside";
    column-gap: map-get($map: $spacing, $key: "xl");

    @include desktop-only {
        grid-template-areas:
            "intro ."
            "form aside";
        grid-template-columns: 2fr 1fr;
    }

    .intro {
        grid-area: intro;
    }

    .contact-form {
        grid-area: form;
    }

    aside {
        grid-area: aside;
    }

}



.successfull-contact {
    display: flex;
    flex-direction: column;
    padding-top: 0;

    .go-back-home {
        margin-top: map-get($map: $spacing, $key: "lg");
        text-decoration: none;
        color: $contrasting-color;
        text-transform: uppercase;

        &:hover,
        &:focus,
        &:active,
        &:focus-visible,
        &:focus-within {
            color: $main-color--light;
            transition: all ease-in-out 0.25s
        }

    }
}
</style>