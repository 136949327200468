import { createRouter, createWebHashHistory } from "vue-router";
import LandingPage from "../pages/LandingPage.vue";

const routes = [
  {
    path: "/",
    name: "landing",
    component: LandingPage,
  },
  {
    path: "/services",
    name: "services",
    component: () => import("../pages/MyServices.vue"),
  },
  {
    path: "/toolsandskills",
    name: "toolsandskills",
    component: () => import("../pages/ToolsAndSkills.vue"),
  },
  {
    path: "/portfolio",
    name: "portfolio",
    component: () => import("../pages/PortfolioPage.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../pages/AboutMe.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../pages/ContactPage.vue"),
  },
  {
    path: "/messagereceived",
    name: "messagereceived",
    component: () => import("../pages/MessageReceived.vue"),
  },
  {
    path: "/workinprogress",
    name: "workinprogress",
    component: () => import("../pages/WorkInProgress.vue"),
  },
  {
    path: "/RGPD",
    name: "RGPD",
    component: () => import("../pages/RGPD.vue"),
  },
  { path: "/404", component: import("../pages/PageNotFound.vue") },
  { path: "/:pathMatch(.*)*", redirect: "/404" },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;
