<template>
  <div class="hero wrapper">
    <div class="hero__content">
      <div class="page__top">
        <div class="hero-img__container">
          <NwImg class="hero-img" src="./assets/images/opening-nuts.svg"
            alt="Image de la page d'accueil : une noix et sa coquille">
          </NwImg>
        </div>
        <div class="page__top__content">

          <h1 class="section-title"><span class="uppercase">Lison Fanuel</span>, PhD</h1>
          <h2 class="page-title">
            Développeuse
          </h2>
        </div>
      </div>
      <rect class="page-divider--blur"></rect>
      <div class="page__bottom">
        <div class="page__bottom__content">
          <h2 class="page-title">
            web <span class="colored">éthique</span>
          </h2>
          <p class="hero__paragraph">
            Je construis des sites web centrés sur l'humain, inclusifs et éco-responsables.
          </p>
          <p class="hero__paragraph">
            <NwImg class="location-icon" src="../assets/icons/location-outline.svg" alt="localisation" />
            Avignon, au coeur de la Provence
          </p>
          <ButtonTemplate v-bind="contactButton" class="full-width-on-mobile no-margin"></ButtonTemplate>
        </div>
      </div>
    </div>
  </div>
  <ServicesPreview>
    <ButtonTemplate text="En savoir plus" icon="/assets/icons/stack.svg"
      classes="button--border full-width-on-mobile rounded-corners" @click="goToRoute('services')">
    </ButtonTemplate>
  </ServicesPreview>
  <div class="section-divider section-divider--top">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
      <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill"></path>
    </svg>
  </div>
  <PortfolioPreview class="section--colored-bg">
    <ButtonTemplate text="Voir plus de projets" icon="/assets/icons/stack.svg"
      classes="button--border full-width-on-mobile rounded-corners" @click="goToRoute('portfolio')">
    </ButtonTemplate>
  </PortfolioPreview>
  <div class="section-divider section-divider--bottom">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
      <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill"></path>
    </svg>
  </div>
  <AboutMePreview />
  <div class="section-divider section-divider--top">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
      <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill"></path>
    </svg>
  </div>
  <ContactPage class="section--colored-bg" />
</template>


<style lang="scss">
$desktop-page-min-height: 560px;

//Landing layout
.hero {
  display: block;
  height: fit-content;
  position: relative;

  .hero__content {
    display: flex;
    flex-direction: column;
    justify-content: center;


    .page-title {
      margin: 0
    }

    .page__top {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      background-color: $neutral-light;
      position: relative;
      z-index: 3;
      min-height: calc(50vh - 2 * $nav-height - 5px);

      @include larger-than-mobile {
        min-height: calc(50vh - $nav-height - 5px);
      }


      &__content {
        margin: 0 map-get(map-get($breakpoints, "mobile"), "lateral-margin");

        @include larger-than-mobile {
          margin-left: calc(map-get(map-get($breakpoints, "desktop"), "lateral-margin"));
          margin-bottom: 0.5rem;
        }

        position: relative;
        z-index: 2;
      }
    }

    .page-divider--blur {
      height: 10px;
      filter: blur(4px);
      background-color: $neutral-light;
      position: relative;
      top: -5px;
    }

    .page__bottom {
      &__content {
        margin: 0 map-get(map-get($breakpoints, "mobile"), "lateral-margin");
        z-index: 10;

        @include larger-than-mobile {
          margin-left: calc(map-get(map-get($breakpoints, "desktop"), "lateral-margin"));
          margin-top: -0.5rem;
          min-height: calc(50vh - 20px);
        }



        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .button--fill {
          margin-left: 0;
        }
      }

    }
  }

  .hero__paragraph {
    font-size: map-get($map: $font-size, $key: "small");
    margin: calc(2 * $spacing-unit) 0;

    &--large-margin {
      margin: calc(4 * $spacing-unit) 0;
    }
  }
}

.hero-img {
  &__container {
    align-self: flex-start;
    height: map-get($map: $spacing, $key: xl);
    width: calc(1.44 * map-get($map: $spacing, $key: xl));
    margin: map-get($map: $spacing, $key: md) map-get(map-get($breakpoints, "mobile"), "lateral-margin") map-get($map: $spacing, $key: sm) map-get(map-get($breakpoints, "mobile"), "lateral-margin");

    @include larger-than-mobile {
      margin: map-get($map: $spacing, $key: sm) map-get(map-get($breakpoints, "desktop"), "lateral-margin");
    }

    @include desktop-only {
      position: absolute;
      height: unset;
      max-height: none;
      margin: 0;
      display: flex;
      justify-content: flex-end;
      padding-right: map-get(map-get($breakpoints, "desktop"), "lateral-margin");
      width: 100%;
      transform: translateY(calc(50% - 8px));

    }
  }

  height:100%;

  @include desktop-only {
    height: unset;
    width: 30%;
  }


}

.location-icon {
  width: 1rem;
  height: 1rem;
}

.hero .button-container {
  display: flex;
  grid-row: button;
  flex-wrap: wrap;
  align-items: flex-start;
  align-self: start;
  justify-content: flex-start;
}

// Landing fonts
.hero .page-title {
  line-height: 1;
  position: relative;
  margin: auto;
  color: $neutral-dark;
  font-weight: normal;
  letter-spacing: -0.1rem;
  text-decoration: none;
  text-transform: none;

  p {
    font-size: map-get($map: $font-size, $key: "x-large");
    margin: 0;
    width: fit-content;
    line-height: 1;

    @include larger-than-mobile {
      font-size: map-get($map: $font-size, $key: "x-large");
    }
  }
}

.hero .section-title {
  color: $neutral-dark;
  margin: 0;
  font-size: map-get($map: $font-size, $key: "large");
  letter-spacing: -0.9px;
  font-weight: normal;

  @include larger-than-mobile {
    font-size: map-get($map: $font-size, $key: "base");
  }
}

.colored {
  color: $main-color--dark;
  font-weight: bold;
}

.uppercase {
  text-transform: uppercase;
}
</style>

<script>
import ButtonTemplate from '@/components/ButtonTemplate.vue';
import AboutMePreview from "@/components/AboutMePreview.vue";
import ContactPage from "@/pages/ContactPage.vue";
import PortfolioPreview from "@/components/PortfolioPreview.vue";
import ServicesPreview from "@/components/ServicesPreview.vue";
import NwImg from '@/components/NwImg.vue';
export default {
  name: "LandingPage",
  data() {
    return {
      contactButton: {
        text: "Me contacter",
        icon: "../assets/icons/paper-plane.svg",
        classes: "button--fill full-width-on-mobile",
        action: () => {
          this.$router.push('/contact')
        },
      },

      opacityLvl: this.adaptOpacity,
    };
  },
  methods: {
    goToRoute(routeName) {
      let routeData = this.$router.resolve({ name: routeName });
      return window.open(routeData.href, '_self');
    },
    isLanding() {
      return this.$route.name === "landing"
    },
  },
  components: { ButtonTemplate, AboutMePreview, ContactPage, PortfolioPreview, ServicesPreview, NwImg }
}


</script>
