<script>
import ArticleHeader from '@/components/ArticleHeader.vue';
import CardTemplate from '@/components/CardTemplate.vue';
import LabelledLogo from '@/components/LabelledLogo.vue';
import ButtonTemplate from '@/components/ButtonTemplate.vue';

export default {
  name: "PortfolioPreview",
  data() {
    return {
      title: "Portfolio",
      subtitle: "Une sélection de mes réalisations",
      projects_web: {
        ArielleMizzi: {
          imageFilename: "mockup-AM",
          title: "Site vitrine d'une somatopathe",
          description: "J’ai conçu et développé ce site pour une cliente somatopathe.<br>🔥 Le défi ? Créer un site vitrine simple, en cohérence avec sa charte graphique, basé principalement sur des technologies <i>open-source</i> et responsables.",
          skills: ["html", "css", "javascript", "jquery"],
          siteLink: `https://www.ariellemizzi-somatopathe.fr/`,
          pictureLink: `https://www.ariellemizzi-somatopathe.fr/`,
          // githubRepoName: `ariellemizzi-somatopathe`,

        },
        SecretSanta: {
          imageFilename: "mockup-secretsanta",
          title: "SecretSanta",
          description: "J’ai conçu et développé SecretSanta pour assister l'organisation des échanges de cadeaux.<br>🔥 Le défi ? Penser une interface utilisateur simple et efficace, et développer une application CRUD en JavaScript.",
          skills: ["html", "css", "javascript", "jquery", "firebase"],
          // Put the  good links here
          siteLink: `https://lisonf.github.io/SecretSanta/`,
          pictureLink: `https://lisonf.github.io/SecretSanta/`,
          // githubRepoName: `SecretSanta`,

        },
        portfolio: {
          imageFilename: "mockup-portfolio",
          title: "Portfolio",
          description: "J’ai conçu et développé mon portfolio.<br>🔥 Le défi ? Apprendre un nouvel outil à chacune des étapes de conception et de développement.",
          skills: ["penpot", "html", "css", "javascript", "vue", "sass"],
          // Put the  good links here
          siteLink: ``,
          pictureLink: `https://www.lisonfanuel.com`,
          // githubRepoName: `Portfolio`,

        },
      },
    };
  },

  methods: {
    goToRoute(routeName) {
      let routeData = this.$router.resolve({ name: routeName });
      return window.open(routeData.href, '_self');
    },
    isLanding() {
      return this.$route.name === "landing"
    },
  },

  components: {
    ArticleHeader, CardTemplate, LabelledLogo, ButtonTemplate
  }
}
</script>

<template>
  <article>
    <ArticleHeader :title="title" :subtitle="subtitle"></ArticleHeader>
    <CardTemplate v-for="project in projects_web" :key="project" v-bind="project" class="card--laptop-image">
      <ul class="logo-list">
        <li v-for="skill in project.skills" :key="skill">
          <LabelledLogo :icon-path="'assets/icons/' + skill + '.svg'" size="small-size" :iconAlt="skill">
          </LabelledLogo>
        </li>
      </ul>
    </CardTemplate>
    <ButtonTemplate v-if="isLanding()" text="Voir plus de projets" icon="/assets/icons/stack.svg"
      classes="see-more button--border full-width-on-mobile rounded-corners" @click="goToRoute('portfolio')">
    </ButtonTemplate>
    <slot name="fullPage"></slot>
  </article>
</template>

<style lang="scss" >
.logo-list {
  display: flex;
}

.card {
  $lateral-margin: map-get($map: map-get($breakpoints, 'mobile'), $key: 'lateral-margin');
  margin: map-get($map: $spacing, $key: "xxl") $lateral-margin;

  @include larger-than-mobile {
    $lateral-margin: map-get($map: map-get($breakpoints, 'desktop'), $key: 'lateral-margin');
    margin: map-get($map: $spacing, $key: "xxxl") $lateral-margin;
  }
}

.card:first-of-type {
  margin-top: 0;
}

.card .card__picture .card__picture__background {
  display: none;
}

.article-list {
  height: fit-content;
  margin-left: map-get($map: $spacing, $key: "lg");

  li {
    list-style-type: disc;
    margin-bottom: map-get($map: $spacing, $key: "base");
  }

  .read-article__link {
    padding: 0;
    text-align: left;
    width: fit-content;
    font-size: 1rem;
    color: $contrasting-color;
    text-decoration: none;
    line-height: 1;
    min-height: calc(map-get($map: $spacing, $key: "md") + 4 * map-get($map: $spacing, $key: "xs"));
    cursor: pointer;

    .button__icon {
      width: 0;
      opacity: 0;
    }

    &:hover,
    &:active,
    &:focus,
    &:focus-within,
    &:focus-visible {
      outline: none;
      color: $main-color--light;
      transition: background-color, box-shadow 0.25s;

      .button__icon {
        margin-right: map-get($map: $spacing, $key: "xs");
        vertical-align: middle;

        line-height: 1;
        text-align: center;
        font-weight: bold;
        width: map-get($map: $spacing, $key: "md");
        opacity: 1;
        transition: opacity ease 0.25s;
        filter: $svg-filter--main-color-light;
      }
    }
  }
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-start;
}
</style>