<script>
import NwImg from '@/components/NwImg.vue'
export default {
  name: "LabelledLogo",
  props: {
    iconLabel: String,
    iconPath: String,
    iconAlt: { type: String, default: "" },
    size: String,
    color: {
      type: String,
      default: "main-color"
    }
  },
  components: { NwImg }
}
</script>

<template>
  <div class="labelled-logo" :class="[size, color]">
    <NwImg :alt="iconLabel || iconAlt" :src="iconPath"></NwImg>
    <legend v-if="iconLabel" class="all-caps labelled-logo__legend"> {{ iconLabel }}</legend>
  </div>
</template>

<style lang="scss">
.labelled-logo {
  display: inline-flex;
  flex-direction: column;
  width: fit-content;
  justify-items: center;
  object-fit: scale-down;
  justify-content: flex-end;

  img {
    align-self: center;
    width: 100%;
  }

  &.main-color img {
    filter: $svg-filter--main-color;
  }

  &.contrasting img {
    filter: $svg-filter--contrasting-color;
  }



  legend {
    margin-top: map-get($map: $spacing, $key: sm);

    @include larger-than-mobile {
      margin-top: map-get($map: $spacing, $key: md);
    }
  }

  &__legend {
    text-transform: uppercase;
    text-align: center;
    font-size: map-get($map: $font-size, $key: small);
  }
}

.medium-size {
  margin: 0 auto 0.5rem auto;
  object-fit: scale-down;

  img {
    height: 3rem;
    width: 3rem;

    @include larger-than-mobile {
      height: 5rem;
      width: 5rem;
    }
  }
}

.small-size {
  margin: 0 1rem 0.5rem auto;
  object-fit: scale-down;

  img {
    height: 2rem;
    width: 2rem;
  }

  legend {
    margin: 0;

  }

}
</style>