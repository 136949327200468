<template>
    <footer>
        <button class="up-arrow-container" @click="scrollToTop">
            <NwImg class="up-arrow" src="/assets/icons/up-arrow.svg" alt="Retour en haut de page" />
        </button>
        <div class="footer__content">
            <div class="buttons-container">
                <ButtonTemplate v-bind="contactButton"></ButtonTemplate>
                <ButtonTemplate v-bind="downloadCVButton"></ButtonTemplate>
            </div>
            <div class="signature-container">
                <p>© Conçu et construit par <wbr>Lison Fanuel - 2022 🔥</p>
                <div class="social-media-container">
                    <a :href="socialMedia.link" v-for="socialMedia in socialMedias" :key="socialMedia"
                        class="social-media" target="_blank" :aria-label=socialMedia.label>
                        <LabelledLogo :icon-path="'assets/icons/' + socialMedia.icon + '.svg'" size="small-size"
                            color="contrasting">
                        </LabelledLogo>
                    </a>
                </div>

            </div>

        </div>
        <nav class="footer__nav">
            <div class="RGPD-link nav__link">
                <router-link to="/RGPD">Mentions légales</router-link>
            </div>
            <ul>
                <li v-for="page in pages" :key="page.id" class="nav__link">
                    <router-link :to="page.url">{{ page.title }}</router-link>
                </li>
            </ul>
        </nav>
    </footer>
</template>

<script>
import ButtonTemplate from '@/components/ButtonTemplate.vue';
import LabelledLogo from '@/components/LabelledLogo.vue';
import NwImg from '@/components/NwImg.vue';
export default {
    name: "TheFooter",
    components: { ButtonTemplate, LabelledLogo, NwImg },
    data() {
        return {
            contactButton: {
                text: "Me contacter",
                icon: "../assets/icons/paper-plane.svg",
                classes: "button--fill full-width-on-mobile",
                action: () => {
                    this.$router.push('/contact')
                },
            },
            downloadCVButton: {
                text: "Télécharger mon CV",
                icon: "../assets/icons/download.svg",
                classes: "button--text full-width-on-mobile",
                action: function () {
                    const filePath = `../assets/CV_Lison Fanuel - web dev.pdf`;
                    window.open(filePath, "_blank");
                },
            },
            socialMedias: [
                { icon: "linkedin", color: "darkGreen", link: "https://www.linkedin.com/in/lison-fanuel-phd-662585bb/", label: "LinkedIn" },
                { icon: "github", link: "https://github.com/LisonF", label: "GitHub" },
                { icon: "researchgate", link: "https://www.researchgate.net/profile/Lison-Fanuel", label: "ResearchGate" }],
            pages: [
                {
                    id: "pageServices",
                    title: "Prestations",
                    url: "/services",
                },
                { id: "pagePortfolio", title: "Portfolio", url: "/portfolio" },
                { id: "pageAbout", title: "A propos", url: "/about" },
                { id: "pageContact", title: "Contact", url: "/contact" },
            ],
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        }
    }
}
</script>

<style lang="scss">
footer {
    margin-top: map-get($map: $spacing, $key: "xxl");

    &.section--colored-bg {
        margin-top: 0;
        padding: 0;
    }

    object {
        pointer-events: none;
    }
}

footer>* {
    padding-left: calc(map-get(map-get($breakpoints, "mobile"), "lateral-margin")/2);
    padding-right: calc(map-get(map-get($breakpoints, "mobile"), "lateral-margin")/2);

    @include larger-than-mobile {
        padding-left: calc(map-get(map-get($breakpoints, "desktop"), "lateral-margin")/2);
        padding-right: calc(map-get(map-get($breakpoints, "desktop"), "lateral-margin")/2);
    }
}

.up-arrow-container {
    display: block;
    height: fit-content;
    width: fit-content;
    padding: 0;
    margin: 0 auto map-get($map: $spacing, $key: "lg") auto;
    position: relative;
    top: 0px;
    outline: none;

    @include larger-than-mobile {
        margin: 0 auto map-get($map: $spacing, $key: "xl") auto;
    }

    filter: $svg-filter--contrasting-color;

    &:hover,
    &:active,
    &:focus {
        filter: $svg-filter--main-color-light;
        top: calc(0px - map-get($spacing, "base"));
        transition: top 0.25s, filter 0.25s;

    }

    .up-arrow {
        height: map-get($map: $spacing, $key: xxl);
        width: map-get($map: $spacing, $key: xxl);

        &:hover,
        &:focus,
        &:active {
            filter: drop-shadow(calc($space-unit / 2) calc($space-unit / 2) calc($space-unit / 4) rgba(0, 0, 0, 1));
        }
    }

    :hover,
    .up-arrow:active,
    .up-arrow:focus {
        position: relative;
    }

    &>* {
        cursor: pointer;
    }
}

.footer__content {
    background-color: $neutral-light ;
    height: fit-content;
    border-top: 2px $white solid;
    border-bottom: 2px $white solid;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: map-get($map: $spacing, $key: "lg") calc(map-get(map-get($breakpoints, "mobile"), "lateral-margin") / 2);
    padding: map-get($map: $spacing, $key: "lg") calc(map-get(map-get($breakpoints, "desktop"), "lateral-margin") / 2);
    flex-direction: column;

    @include desktop-only {
        flex-direction: row;
    }

    .buttons-container {
        height: max-content;
        width: 100%;

        @include larger-than-mobile {
            width: fit-content;
        }

        display: flex;
        flex-direction: column;

        .button {
            display: inline-block;
            margin-left: 0;

            &--text {
                padding-left: 0;
                padding-right: 0;

                &:hover,
                &:focus,
                &:active,
                &:focus-visible,
                &:focus-within {
                    padding: map-get($map: $spacing, $key: "xs") map-get($map: $spacing, $key: "lg");
                    transition: padding 0.25s ease-in-out;
                }
            }
        }
    }

    .signature-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: map-get($map: $spacing, $key: "lg");

        @include desktop-only {
            align-items: flex-end;
        }
    }
}

.social-media {
    width: fit-content;

    .labelled-logo {
        border-radius: 5px;

        img {
            margin: 0;
        }
    }

    &:hover,
    &:active,
    &:focus {
        outline: none;

        .labelled-logo {
            box-shadow: calc($spacing-unit / 2) calc($spacing-unit / 2) calc($spacing-unit / 4) scale-color($color: $neutral-dark, $alpha: -50%);
            transition: all 0.25s ease-in-out;

            img {
                filter: $svg-filter--main-color-light;
                transition: all 0.25s ease-in-out;
            }
        }

    }
}


.footer__nav {
    min-height: $nav-height;

    @include desktop-only {
        height: $nav-height;
        flex-direction: row;
        wrap: nowrap;
    }

    background-color: $neutral-light;
    border-bottom: $white 2px solid;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 0 calc(map-get(map-get($breakpoints, "mobile"), "lateral-margin") / 2);
    align-items: flex-end;

    flex-wrap: wrap-reverse;
    flex-direction: column-reverse;

    @include desktop-only {
        flex-direction: row;
        align-items: center;
        padding: 0 calc(map-get(map-get($breakpoints, "desktop"), "lateral-margin") / 2);
    }


    ul {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: map-get($map: $spacing, $key: "lg");
        flex-direction: column;

        @include desktop-only {
            flex-direction: row;
            margin-bottom: 0;
            margin-top: 0;
        }

    }

    .nav__link {
        @include nav-links;
        width: fit-content;
        margin-bottom: map-get($map: $spacing, $key: "lg");
        flex-grow: 0;

        @include desktop-only {
            margin-bottom: 0;
        }


    }
}
</style>