<template>
  <header class="main-header">
    <div class="logo-container">
      <router-link to="/" class="logo-container__logo" @click="hideMenu">
        <img class="bracket left" src="@/assets/images/curly-brackets-left.svg" alt="">
        <img class="nut" src="@/assets/images/nut-empty.svg" alt="Lison Fanuel, développeuse web" />
        <div class="sliding-bracket">
          <p class="text">Lison Fanuel, PhD <br><span class="colored">Développeuse web</span></p>
          <img class="bracket right" src="@/assets/images/curly-brackets-right.svg" alt="" />
        </div>
      </router-link>
    </div>

    <nav class="nav" :class="{ 'is-shown': isShown }">
      <div class="menu-btn-container" @click="toggleMenu">
        <!-- Add role to the button -->
        <button aria-label="menu" class="hamburger hamburger--squeeze" :class="{ 'is-active': isActive }" type="button">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
      <ul>
        <li v-for="page in pages" :key="page.id" @click="toggleMenu" class="nav__link links-container__link"
          :class="{ 'is-shown': isShown }">
          <router-link :to="page.url">{{
          page.title
          }}</router-link>
        </li>
      </ul>

    </nav>
  </header>
</template>

<script>

export default {
  name: "TheHeader",
  data() {
    return {
      pages: [
        {
          id: "pageServices",
          title: "Prestations",
          url: "/services",
        },
        { id: "pagePortfolio", title: "Portfolio", url: "/portfolio" },
        { id: "pageAbout", title: "A propos", url: "/about" },
        { id: "pageContact", title: "Contact", url: "/contact" },
      ],
      isShown: false,
      isActive: false,
    };
  },
  methods: {
    toggleMenu: function () {
      this.isShown = !this.isShown;
      this.isActive = !this.isActive;
    },
    hideMenu: function () {
      if (this.isShown) {
        this.isShown = !this.isShown;
        this.isActive = !this.isActive;
      }
    },
  },
}
</script>

<style lang="scss">
.main-header {
  min-height: $nav-height;

  @include desktop-only {
    height: $nav-height;
  }

  background-color: $neutral-light;
  border-bottom: $white 2px solid;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 0 calc(map-get(map-get($breakpoints, "mobile"), "lateral-margin") / 2) 0 map-get(map-get($breakpoints, "mobile"), "lateral-margin");

  @include larger-than-mobile {
    padding: 0 calc(map-get(map-get($breakpoints, "desktop"), "lateral-margin") / 2) 0 map-get(map-get($breakpoints, "desktop"), "lateral-margin");
  }

  .logo-container__logo {
    text-decoration: none;
    display: flex;
    align-items: center;
    height: $nav-height;
    width: fit-content;

    .nut {
      height: $nav-height;
      width: 46px;
      object-fit: contain;
      padding: $spacing-unit 0;

    }

    .sliding-bracket {
      display: flex;
      align-items: center;
    }

    .bracket {
      height: calc($nav-height * 0.75);
      width: 13px;
      object-fit: contain;
      padding: $spacing-unit 0;

      &.left {
        margin-right: -2px;
      }

      &.right {
        margin-left: -2px;
      }
    }

    p {
      position: absolute;
      visibility: hidden;
      opacity: 0;
      width: 0;
      padding-left: map-get($map: $spacing, $key: xs);
      padding-right: map-get($map: $spacing, $key: xs);
      color: $neutral-dark;
      font-size: map-get($font-size, small);
      line-height: 1;
      font-weight: bold;
      text-align: right;

      .colored {
        font-weight: normal;
      }
    }

    &:hover,
    &:active,
    &:focus {
      cursor: pointer;
      outline: none;

      p {
        position: static;
        visibility: visible;
        width: 100%;
        opacity: 1;

        transition: width 0.25s ease-out,
          opacity 0.5s 0.25s ease-in-out;
      }

    }
  }


  .nav {
    height: $nav-height;
    justify-content: flex-end;

    @include desktop-only {
      display: flex;
      margin: auto 0;
      justify-content: center;
    }

    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-around;

      @include desktop-only {
        align-items: center;
        flex-direction: row;
        justify-content: baseline;
        gap: calc($spacing-unit * 2);
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    padding: 0;
    margin-top: 0;
    margin-bottom: 0;

    &.is-shown {
      height: fit-content;
    }

    &__link {
      display: none;

      @include desktop-only {
        display: initial;
      }

      @include nav-links;

      &.is-shown {
        @include smaller-than-desktop {
          display: initial;
          margin-bottom: calc($spacing-unit * 3);

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: $spacing-unit;
          }
        }
      }
    }
  }
}


// Menu
.menu-btn-container {
  height: $nav-height;
  display: flex;
  justify-content: flex-end;

  @include desktop-only {
    display: none;
  }
}
</style>
