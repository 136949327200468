<script>
export default {
  name: "SectionTemplate",
  props: {
    title: String,
    subtitle: String,
  },
}
</script>

<template>
  <section>
    <header class="section-header">
      <h2 class="section-title">{{ title }}</h2>
      <div role="doc-subtitle">{{ subtitle }}</div>
    </header>
    <slot></slot>
  </section>
</template>

<style lang="scss">
.section-header {
  margin-bottom: 2rem;

  @include prevent-code-inflating;

  @include larger-than-mobile {
    margin-bottom: 4rem;
  }
}

.section--colored-bg {
  background-color: $neutral-light;
  padding-top: 5vh;
  padding-bottom: 5vh;
  outline: 1px $neutral-light solid;
}

.section-divider {
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);

  svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 50px;
  }

  .shape-fill {
    fill: $neutral-light;
  }

  &--bottom {
    transform: rotateY(180deg);
  }
}
</style>