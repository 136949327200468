<template>
    <p>Chaque projet est unique, parlez-moi du vôtre !
    </p>
    <div class="contact-form" accept-charset="UTF-8">
        <div class="form-field-container">
            <div class="form-field">
                <input aria-label="Votre nom" id="name" name="name" v-model="name" @change="isFilled" class="input-text"
                    :class="{ 'is-error': v$.name.$error }" type="text">
                <label class="label" for="name">Nom</label>
            </div>
            <div class="error-message" v-if="v$.name.$error"> {{ v$.name.$errors[0].$message }} </div>
        </div>
        <div class="form-field-container">
            <div class="form-field">
                <input aria-label="Votre email" name="email" id="email" v-model="email" @change="isFilled"
                    class="input-text" :class="{ 'is-error': v$.email.$error }" type="text">
                <label class="label" for="email">Email</label>
            </div>
            <div class="error-message" v-if="v$.email.$error"> {{ v$.email.$errors[0].$message }} </div>
        </div>
        <div class="form-field-container">
            <div class="form-field">
                <input name="phone" id="phone" v-model="phone" @change="isFilled" class="input-text" type="text">
                <label class="label" for="phone">Téléphone (optionnel)</label>
            </div>
        </div>
        <div class="form-field-container">
            <div class=" form-field">
                <textarea id="message" name="message" v-model="message" @change="isFilled" class="input-text message"
                    :class="{ 'is-error': v$.message.$error }">
                                </textarea>
                <label class="label" for="message">Message</label>
            </div>
            <div class="error-message" v-if="v$.message.$error"> {{ v$.message.$errors[0].$message }} </div>
        </div>
        <ButtonTemplate v-bind="sendMessage" @click="sendEmail"></ButtonTemplate>
        <div class="error-message send-message" v-if="isSendingError">Ce formulaire n'a pas pu être envoyé.
            Merci de
            réessayer
            ultérieurement.</div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';

import { required, email, helpers } from '@vuelidate/validators';
import emailjs from '@emailjs/browser';
import ButtonTemplate from '@/components/ButtonTemplate.vue';

export default {
    name: "ContactForm",
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            name: "",
            email: "",
            phone: "",
            message: "",
            sendMessage: {
                text: "Envoyer",
                icon: "../assets/icons/paper-plane.svg",
                classes: "button--fill full-width-on-mobile",
            },
            isSent: false,
            isSendingError: false,
            errorRequiredErrorMessage: "Merci de remplir ce champ.",
            emailRequiredErrorMessage: "Merci d'indiquer une adresse email valide.",
            downloadCVButton: {
                text: "Télécharger mon CV",
                icon: "../assets/icons/download.svg",
                classes: "button--text no-margin",
                action: function () {
                    const filePath = `../assets/CV_Lison Fanuel - web dev.pdf`;
                    window.open(filePath, "_blank");
                },
            },
            socialMedias: [
                { icon: "linkedin", color: "darkGreen", link: "https://www.linkedin.com/in/lison-fanuel-phd-662585bb/" },
                { icon: "github", link: "https://github.com/LisonF" },
                { icon: "researchgate", link: "https://www.researchgate.net/profile/Lison-Fanuel" }],


        }
    },
    validations() {
        return {
            name: {
                required: helpers.withMessage(this.errorRequiredErrorMessage, required)
            },
            email: {
                required: helpers.withMessage(this.errorRequiredErrorMessage, required),
                email: helpers.withMessage(this.emailRequiredErrorMessage, email)
            },
            phone: {},
            message: {
                required: helpers.withMessage(this.errorRequiredErrorMessage, required)
            },
        }
    },
    methods: {
        isFilled(e) {
            let currentTarget = e.currentTarget;
            let inputLength = currentTarget.value.length;
            if (inputLength > 0) {
                currentTarget.classList.add('is-filled')
            }
            else {
                currentTarget.classList.remove('is-filled')

            }
        },
        sendEmail() {
            this.v$.$validate()
            if (!this.v$.$error) {
                // if ANY fail validation    
                var templateParams = {
                    name: this.name,
                    message: this.message,
                    email: this.email,
                    phone: this.phone
                };
                emailjs.send(process.env.VUE_APP_EMAILJS_SERVICE, process.env.VUE_APP_EMAILJS_TEMPLATE, templateParams, process.env.VUE_APP_EMAILJS_PUBLICKEY)
                    .then((response) => {
                        this.isSent = true;
                        console.log('SUCCESS!', response.status, response.text);
                        // window.scrollTo(0, 0);
                        this.$router.push(`/messagereceived`)
                    }, (error) => {
                        this.isSendingError = true;
                        console.log('FAILED...', error);
                    });
            }
        },
    },
    components: { ButtonTemplate }

}
</script>

<style lang="scss">
.contact-form {
    margin-top: map-get($map: $spacing, $key: "xl");

    margin-bottom: map-get($map: $spacing, $key: "xl");

    .form-field-container:not(.submit) {
        margin: map-get($map: $spacing, $key: "xl") 0;

        &:first-of-type {
            margin-top: 0;
        }

        &:last-of-type {
            margin-bottom: map-get($map: $spacing, $key: "md")
        }
    }

    .form-field {
        position: relative;


    }

    .input-text {
        position: relative;
        display: block;
        width: 100%;
        height: map-get($map: $spacing, $key: "xl");
        border-width: 0 0 4px 0;
        border-color: $contrasting-color;
        border-radius: 0;
        background-color: lighten($neutral-light, 7%);

        &:hover,
        &:focus,
        &.is-filled {
            outline: none;
            border-width: 4px;
            border-color: $main-color--light;
            border-style: solid;

            &+.label {
                font-weight: bold;
            }
        }

        &:focus+.label,
        &.is-filled+.label {
            transform: translateY(calc(0px - map-get($map: $spacing, $key: "xl") + map-get($spacing, "xs")));
            font-weight: bold;
        }

        &.message {
            height: map-get($map: $spacing, $key: "xxxl");
            resize: none;

            &:focus+.label,
            &.is-filled+.label {
                transform: translateY(calc(0px - map-get($map: $spacing, $key: "xxxl") + map-get($spacing, "xs")));
            }

        }

        &.is-error {
            border-color: $error-color;
            border-width: 0 0 4px 0;
            border-style: solid;
        }
    }

    .label {
        position: absolute;
        left: map-get($map: $spacing, $key: "sm");
        bottom: map-get($map: $spacing, $key: "base");
        color: $neutral-dark;
        cursor: text;
        transition: transform 0.25s ease-in-out;
    }

    .button {
        margin: 0;
        width: 100%;
    }

    .error-message {
        color: $error-color;

        &.send-message {
            margin-top: map-get($map: $spacing, $key: "base")
        }
    }
}
</style>