<template>
  <div class="card" :class="imageSide">
    <a v-if="pictureLink.length > 0" class="card__picture" :href="pictureLink" target="_blank">
      <picture>
        <source :srcset="'/assets/images/'+imageFilename+'.webp'" type="image/webp">
        <source :srcset="'/assets/images/'+imageFilename+'.png'" type="image/png">
        <NwImg class="card__picture__img" :src="'/assets/images/'+imageFilename+'.png'" :alt="title" />
      </picture>
    </a>
    <div v-else class="card__picture">
      <NwImg class="card__picture__img" :src="'/assets/images/'+imageFilename+'.png'" :alt="title" />
    </div>
    <legend class="card__legend">
      <h2 class="card-title">{{ title }}</h2>
      <p><span v-html="description"></span></p>
      <slot></slot>
    </legend>
    <div class="button-container">
      <ButtonTemplate v-if="githubRepoName.length > 0" v-bind="GitHubButton" :action="linkToRepo"></ButtonTemplate>
      <ButtonTemplate v-if="siteLink.length > 0" v-bind="SiteLinkButton" :action="linkToSite"></ButtonTemplate>
      <slot name="buttons"></slot>
    </div>

  </div>
</template>
<script >
import ButtonTemplate from "@/components/ButtonTemplate.vue";
import NwImg from "@/components/NwImg.vue";

export default {
  name: "CardTemplate",
  props: {
    imageFilename: String,
    title: String,
    description: String,
    imageSide: { type: String, default: "left" },
    siteLink: { type: String, default: `` },
    pictureLink: { type: String, default: `` },
    githubRepoName: { type: String, default: `` },
  },
  data() {
    return {
      // Make the buttons links depending on the variables of each project
      GitHubButton: {
        text: "Code source",
        icon: "../assets/icons/code.svg",
        classes: "button--text full-width-on-mobile",
      },
      SiteLinkButton: {
        text: "Voir le site",
        icon: "../assets/icons/external-link.svg",
        classes: "button--fill full-width-on-mobile",
      },
    }
  },
  methods: {
    linkToSite() {
      window.open(this.siteLink, '_blank')
    },
    linkToRepo() {
      window.open(`https://github.com/LisonF/` + this.githubRepoName, '_blank')
    }
  },
  components: { ButtonTemplate, NwImg }
}
</script>

<style lang="scss" >
.card {
  display: grid;
  grid-template-rows: auto;
  grid-template-areas:
    "image"
    "legend"
    "buttons";

  @include desktop-only {
    grid-template-columns: 1.5fr 2fr;
    grid-template-areas:
      "image legend"
      "buttons buttons";
  }




  .card__picture {
    max-width: 100%;
    max-height: 100%;
    float: inline-start;
    margin: 1rem 1rem auto 0;
    grid-area: image;
    display: flex;
    justify-content: center;

    &__img {
      width: 100%;
      max-width: 400px;
      height: fit-content;
      object-fit: scale-down;


    }

  }

  margin: map-get($map: $spacing, $key: "base") 0 map-get($map: $spacing, $key: "lg") 0;

  @include larger-than-mobile {

    margin-top: -2vw;

    &.left .card__picture {
      margin-right: map-get($map: $spacing, $key: "lg");

      .card__picture__background {
        transform: rotate(180deg);
      }
    }

    &.right {
      flex-direction: row-reverse;

      .card__picture {
        margin-left: map-get($map: $spacing, $key: "lg");
      }

      .card__legend {
        text-align: right;
      }
    }
  }

  .card__legend {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-area: legend;
    line-height: 2;
    margin: 2vw 0;

    h4,
    p {
      margin: 0;
      float: left;
      @include prevent-code-inflating;
    }
  }

  .button-container {
    grid-area: buttons;
    display: flex;
    flex-direction: column-reverse;

    @include larger-than-mobile {
      flex-direction: row;
    }
  }

  &:first-of-type {
    margin-top: 0;
  }
}
</style>

